<template>
  <div ref="dropdown">
    <perfect-scrollbar
      ref="scrollbar"
      class="scroll h-100 px-3 nested-select-dropdown-scrollbar"
      :options="scrollbarOptions"
    >
      <!------------ START: Carousel ------------>
      <v-carousel
        v-model="carousel"
        :show-arrows="false"
        hide-delimiters
        height="auto"
      >
        <v-carousel-item v-if="items.length">
          <b-list-group>
            <NestedSelectOption
              v-for="(item, i) in items"
              :key="i"
              :item="item"
              @open="addItem"
              @select="select"
            />
          </b-list-group>
        </v-carousel-item>
        <span v-else>{{ $t("formHelper.noSelectOptions") }}</span>

        <v-carousel-item v-for="(item, i) in carouselItems" :key="i">
          <NestedSelectParent
            :item="item"
            :level="i + 1"
            @open="addItem"
            @select="select"
            @back="removeItem"
          />
        </v-carousel-item>
      </v-carousel>
      <!------------ END: Carousel ------------>
    </perfect-scrollbar>
  </div>
</template>

<script>
import NestedSelectOption from "@/components/Tools/FormHelper/Components/NestedSelect/NestedSelectOption.vue";
import NestedSelectParent from "@/components/Tools/FormHelper/Components/NestedSelect/NestedSelectParent.vue";

export default {
  components: { NestedSelectParent, NestedSelectOption },
  model: {
    prop: "show"
  },
  props: {
    // Injected per v-model
    show: {
      type: Boolean
    },
    // Input string to filter variables
    filter: {
      type: String,
      default: ""
    },
    filterValue: {
      type: String,
      default: ""
    },
    // Element which dropdown should be attached to
    el: {
      type: HTMLElement,
      default: () => {}
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      carousel: 0,
      carouselItems: [],
      currentFullName: "",
      target: {
        x: 0,
        y: 0,
        width: 0
      },
      scrollbarOptions: {
        wheelPropagation: false
      }
    };
  },
  computed: {},
  watch: {
    // Remove all carousel items to show root item when opened again
    show: function () {
      if (this.show === false) {
        this.onClose();
      } else {
        this.onOpen();
      }
    }
  },
  created() {
    window.addEventListener("click", this.focusChanged);
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("click", this.focusChanged);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    // Add carousel item
    addItem(payload) {
      if (!payload?.children) return;

      this.carouselItems.push({
        label: payload.label ?? payload.value,
        value: payload.value ?? payload.label,
        children: payload?.children ?? []
      });

      setTimeout(() => {
        this.carousel++;
        this.$refs.scrollbar.$el.scrollTop = 0;
      }, 100);
    },
    // Remove last carousel item
    removeItem() {
      this.carousel--;
      setTimeout(() => {
        this.carouselItems.pop();
        this.$refs.scrollbar.$el.scrollTop = 0;
      }, 300);
    },
    select(payload) {
      this.$emit("select", payload);
    },
    focusChanged(e) {
      // Check if dropdown is already hidden
      if (!this.show) {
        return;
      }
      // Check if click was on dropdown
      let hasFocus = this.$refs.dropdown.contains(e.target);
      // Check if click was on input
      let inputFocus = this.el.contains(e.target);
      // If none of them above was clicked, hide dropdown
      if (!hasFocus && !inputFocus) {
        this.$emit("input", false);
      } else {
        if (hasFocus) {
          this.el.focus();
        }
        // Else update position
        this.setTarget();
      }
    },
    // On open check if a nested variable is selected and show the correct nested item
    onOpen() {
      // Update position
      this.setTarget();
      let items = this.searchItems(this.items);

      for (const item of items) {
        this.addItem(item);
        this.carousel++;
      }
    },
    searchItems(array, ancestors = []) {
      return array.flatMap(item => {
        // Check if the value includes the search string
        const valueMatch = item.value === this.filter;

        // Recursively filter children if they exist
        const filteredChildren = item.children
          ? this.searchItems(Object.values(item.children), [...ancestors, item])
          : [];

        // Include the current item and its filtered children if value matches
        return valueMatch
          ? [...ancestors, item, ...filteredChildren]
          : filteredChildren;
      });
    },
    onClose() {
      this.carousel = 0;
      this.carouselItems = [];
    },
    setTarget() {
      let rect = this.el.getBoundingClientRect();
      this.target = {
        x: rect.x,
        y: rect.y + rect.height
      };
    },
    onScroll() {
      // If dropdown is shown
      if (this.show) {
        // Update position
        this.setTarget();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.zindex-10 {
  z-index: 10;
}

.nested-select-dropdown {
  left: v-bind(target[ "x"]) px;
  top: v-bind(target[ "y"]) px;
  width: 100%;

  &-scrollbar {
    max-height: 250px;
    background: #fff;
    padding: 10px;
  }
}
</style>
