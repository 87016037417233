<template>
  <div>
    <div class="mb-2">
      <span class="btn btn-sm btn-clean btn-icon mr-2" @click="back">
        <i class="fal fa-angle-left" />
      </span>
      <span class="font-weight-bold">
        {{ item.label ?? item.value }}
      </span>
    </div>
    <b-list-group>
      <NestedSelectOption
        v-for="(value, key) in Object.values(item.children || {})"
        :key="key"
        :item="value"
        @open="open"
        @select="select"
      />
    </b-list-group>
  </div>
</template>

<script>
import NestedSelectOption from "@/components/Tools/FormHelper/Components/NestedSelect/NestedSelectOption.vue";

export default {
  components: { NestedSelectOption },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    filter: {
      type: String,
      default: ""
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    open(payload) {
      this.$emit("open", payload);
    },
    select(payload) {
      this.$emit("select", payload);
    },
    back() {
      this.$emit("back");
    }
  }
};
</script>
