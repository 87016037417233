<template>
  <div class="w-100">
    <!------------ START: Form field input ------------>
    <div
      ref="input"
      class="form-control"
      :contenteditable="!isDisabled"
      :data-placeholder="placeholder"
      :class="[
        validationClass,
        showPlaceholder ? 'empty' : '',
        field.name + '-' + options.groupIndex
      ]"
      v-bind="{ disabled: isDisabled }"
      @input="onNestedSelectSearch"
      @keyup.esc="showNestedSelectDropdown = !showNestedSelectDropdown"
      @keydown.tab="showNestedSelectDropdown = false"
    />
    <!------------ END: Form field input ------------>
    <!------------ START: Nested Select dropdown ------------>
    <v-menu
      v-model="showNestedSelectDropdown"
      :activator="'.' + field.name + '-' + options.groupIndex"
      offset-y
      :close-on-content-click="false"
    >
      <NestedSelectDropdown
        v-if="isMounted"
        ref="dropdown"
        v-model="showNestedSelectDropdown"
        :show="showNestedSelectDropdown"
        :filter="filter"
        :filter-value="filterValue"
        :items="filteredItems"
        :el="$refs.input"
        @select="setValue"
      />
    </v-menu>
    <!------------ END: Nested Select dropdown ------------>
  </div>
</template>

<script>
import { base, input } from "@/components/Tools/FormHelper/Helper/mixins";
import NestedSelectDropdown from "@/components/Tools/FormHelper/Components/NestedSelect/NestedSelectDropdown.vue";
import { highlight } from "@/components/Tools/FormHelper/Helper/functions";

export default {
  name: "NestedSelect",
  components: {
    NestedSelectDropdown
  },
  mixins: [base, input],
  inject: ["options"],
  expose: ["toggleShowNestedSelectDropdown"],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showNestedSelectDropdown: false,
      filterValue: ""
    };
  },
  computed: {
    value: {
      get: function () {
        // Return inherited value
        return this.defaultValue;
      },
      set: function (value) {
        // Trigger change event firstly, to add old value to payload
        this.onChange(value);
        // Trigger input event with new value
        this.onInput(value);
      }
    },
    computedValue: {
      get: function () {
        let value = this.$v.value.$model;
        // If input was not touched yet, don't show null
        if (value === null) {
          value = "";
        }
        // Always get value as string to be editable
        return String(value);
      },
      set: function (value) {
        // Set value cast or just as string
        this.lastValueSet = value;
        this.$v.value.$model = value;
      }
    },
    filteredItems() {
      return this.filterItems(this.items);
    },
    placeholder: function () {
      return this.$t("formHelper.selectOption");
    },
    enableVariables: function () {
      return false;
    }
  },
  watch: {
    // Watch for v-model value and update contenteditable if changed from outside
    value: function (val) {
      if (val !== this.lastValueSet) {
        this.lastValueSet = val;
      }
    }
  },
  mounted() {
    this.isMounted = true;

    const option = this.findOptionByValue(this.items);
    if (option) this.setValue(option);
  },
  methods: {
    toggleShowNestedSelectDropdown(value) {
      this.showNestedSelectDropdown = value;
    },
    emitToggle() {
      this.$emit("toggle-nested-select-field");
    },
    setValue(value) {
      this.computedValue = value.value ?? value.label;
      this.$refs.input.innerHTML = highlight(
        value.label ?? value.value,
        false,
        false,
        "string"
      );

      this.showNestedSelectDropdown = false;
    },
    onNestedSelectSearch(event) {
      this.filterValue = event.target.innerText.trim();
      this.computedValue = null;
      this.$refs.dropdown.carouselItems = [];
    },
    filterItems(array) {
      let items = JSON.parse(JSON.stringify(array));

      return items
        .map(item => {
          // Check if the label includes the search string
          const labelMatch = item.label.includes(this.filterValue);

          // Recursively filter children if they exist
          const filteredChildren = item.children
            ? this.filterItems(Object.values(item.children), this.filterValue)
            : [];

          // Include the current item if label matches or if any filtered children exist
          return labelMatch || filteredChildren.length > 0
            ? { ...item, children: filteredChildren }
            : null;
        })
        .filter(Boolean);
    },
    findOptionByValue(items) {
      const foundOption = Object.values(items).find(
        ({ value }) => value === this.value
      );

      if (foundOption) {
        return foundOption;
      }

      for (const item of Object.values(items)) {
        if (item.children || item.children?.length) {
          const result = this.findOptionByValue(item.children, this.value);
          if (result) {
            return result;
          }
        }
      }
    }
  }
};
</script>
