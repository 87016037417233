<template>
  <div>
    <!------------ START: Variables field ------------>
    <VariablesField
      v-if="isVariablesField"
      v-model="$v.value.$model"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ END: Variables field ------------>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper
      v-else
      ref="fieldWrapper"
      :class="{ 'has-reload-button': field?.optionsConfig?.async }"
      :field="field"
      :input-group="true"
      :variables-field-active="variablesFieldActive"
      @toggle-variables-field="toggleVariablesField"
    >
      <NestedSelect
        v-if="hasChildren"
        ref="nestedSelect"
        v-model="$v.value.$model"
        :items="items"
        :field="field"
      >
      </NestedSelect>

      <!------------ START: Select ------------>
      <v-autocomplete
        v-else-if="!isVariablesField"
        ref="select"
        v-model="$v.value.$model"
        :items="items"
        item-text="label"
        item-value="value"
        class="form-control mt-0"
        :class="[validationClass, isDisabled ? 'disabled' : '']"
        :clearable="field.clearable"
        clear-icon="fal fa-xmark icon-md"
        append-icon="fal fa-chevron-down icon-md"
        :menu-props="{ offsetY: true }"
        :no-data-text="$t('formHelper.noSelectOptions')"
        :placeholder="getSnippet(field.placeholder)"
        :disabled="isDisabled"
        :loading="isLoading || isLoadingDataOptions"
        :search-input.sync="searchInput"
        @focus="onOpen"
        @keydown="search"
      >
        <template v-if="field?.optionsConfig?.async" #no-data>
          <div class="async-options-wrapper">
            <div v-if="isLoadingDataOptions" class="select-loading-wrapper">
              <Loader />

              <div class="alert alert-info">
                {{ $t("formHelper.dataOptions.loading") }}
              </div>
            </div>

            <div v-else class="alert alert-info">
              {{ $t("formHelper.dataOptions.search") }}
            </div>
          </div>
        </template>

        <template #item="data">
          <div class="d-flex align-items-center justify-content-between w-100">
            {{ data.item.label }}
            <i
              v-if="data.item.info"
              v-b-popover.hover.top="data.item.info"
              class="fal fa-circle-question"
            />
          </div>
        </template>
      </v-autocomplete>

      <OptionsReloadButton
        v-if="field?.optionsConfig?.async"
        @reload="reloadDataOptions"
      ></OptionsReloadButton>
      <!------------ END: Select ------------>
      <!------------ START: Hints ------------>
      <template v-if="field.hint" #hints>
        {{ getSnippet(field.hint) }}
      </template>
      <!------------ END: Hints ------------>
    </FieldWrapper>
    <!------------ END FieldWrapper ------------>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip
      v-if="isMounted"
      :target="$refs.fieldWrapper?.$refs.inputGroup"
      :errors="validationErrors"
    />
    <!------------ END: Error messages tooltip ------------>
  </div>
</template>

<script>
import {
  base,
  eagerValidation,
  select,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";
import VariablesField from "@/components/Tools/FormHelper/Components/InputVariables";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import NestedSelect from "@/components/Tools/FormHelper/Components/NestedSelect.vue";
import Loader from "@/components/Tools/Loader.vue";
import OptionsReloadButton from "@/components/Tools/FormHelper/Components/OptionsReloadButton.vue";

export default {
  components: {
    OptionsReloadButton,
    NestedSelect,
    FieldWrapper,
    ErrorsTooltip,
    VariablesField,
    Loader
  },
  mixins: [base, select, variablesField, eagerValidation],
  props: {},
  data() {
    return {
      isMounted: false
    };
  },
  computed: {
    hasChildren() {
      return this.items.some(
        option => Object.values(option?.children || {})?.length
      );
    }
  },
  watch: {
    isLoadingDataOptions(newValue, oldValue) {
      if (
        newValue === false &&
        oldValue === true &&
        this.field?.optionsConfig?.async &&
        this.hasChildren
      ) {
        this.$refs.nestedSelect.toggleShowNestedSelectDropdown(true);
      }
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.select-loading-wrapper {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.async-options-wrapper {
  padding: 10px 10px 0;
}

.has-reload-button {
  :deep(.input-group) {
    display: grid;
    grid-template-columns: 1fr auto;

    > :first-child {
      width: 100%;
    }
  }
}
</style>
