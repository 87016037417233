<template>
  <b-list-group-item
    class="p-0 d-flex align-items-stretch justify-content-between"
  >
    <!------------ START: Item text ------------>
    <div
      class="btn btn-clean rounded-0 text-left flex-grow-1 px-3 py-2 d-flex flex-nowrap align-items-center"
      @click="select"
    >
      <span class="text-break">{{ String(item.label ?? item.value) }}</span>
    </div>
    <!------------ END: Item text ------------>
    <!------------ START: Item show children ------------>
    <div
      v-if="hasChildren"
      class="btn btn-clean rounded-0 px-3 py-2 border-left d-flex align-items-center justify-content-center"
      @click="openDetails"
    >
      <i class="fal fa-angle-right align-middle" />
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    hasChildren: function () {
      return Object.values(this.item.children || {})?.length;
    }
  },
  mounted() {},
  methods: {
    // Emit select event
    select() {
      this.$emit("select", this.item);
    },
    // Emit event to add carousel item with variable's object value as items
    openDetails() {
      const payload = {
        children: this.item?.children ?? [],
        label: this.item.label,
        value: this.item.value
      };
      this.$emit("open", payload);
    }
  }
};
</script>
